<template>
  <dialog-outline persistent v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- context -->
    <template v-slot:context>
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions v-if="!isEmbeddedViewer">
      <base-button @click="onOk" :color="promptColorClass">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="onCancel">
        <template v-slot:default>
          {{ dialogCloseCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";

// services
import {
  fullRecordName,
  fullNewRecordVersionExtensionName
} from "@/services/record/recordService";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";

export default {
  name: "CheckInSuccessfulDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    isEmbeddedViewer: {
      type: Boolean,
      required: true
    },
    performActionOk: {
      type: Function,
      required: true
    },
    performActionCancel: {
      type: Function,
      required: true
    },
    latestVersion: {
      type: Number,
      required: true
    },
    checkInExtension: {
      type: String,
      default: ""
    }
  },

  computed: {
    /**
     * Check In  Operation
     * @return {{name: string, icon: string, label: string}} checkIn Operation
     */
    operation() {
      return findRecordOperation(recordOperation.CheckIn);
    },

    /**
     * Check In operation Label
     * @return {string} checkIn operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Check In Dialog title
     * @return {string} Check in Success Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} ${fullNewRecordVersionExtensionName(
        this.record,
        this.latestVersion,
        this.checkInExtension
      )}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${fullRecordName(this.record)}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return "Ok";
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Check-In Successful`;
    },

    promptSubtitle() {
      return !this.isEmbeddedViewer
        ? `Press Ok to jump to the updated latest file version
           Press Close to close Document Viewer`
        : "Press Close to close Document Viewer";
    },

    /**
     * current Prompt Type
     * remarks:  overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.success;
    },

    /**
     * Prompt Hint
     * remarks:  overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return "File has been Checked In Successfully";
    }
  },

  methods: {
    /**
     * Performs Navigates New Version Page
     */
    async onOk() {
      try {
        this.clearAlert();
        this.performActionOk();
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Performs Close Document Viewer
     */
    async onCancel() {
      try {
        this.clearAlert();
        this.performActionCancel();
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;
    }
  }
};
</script>
